(function () {
	'use strict';

	angular.module('l4b')
		.controller('MyAccountController', MyAccountController);

	MyAccountController.$inject = ['$state', '$scope', '$location', 'Constants', 'FlowService', 'MyAccountService', 'Utils', 'DTOFactory', '$rootScope', '$stateParams'];

	function MyAccountController($state, $scope, $location, Constants, FlowService, MyAccountService, Utils, DTOFactory, $rootScope, $stateParams) {
		var vm = this,
			tab = null,
			myAccountState = $state.get(Constants.Page.MyAccount);

		angular.extend(vm, {
			// Injects
			Constants: Constants,

			// 
			$onInit: init,
			logOut: logOut,
			clickTab: MyAccountService.GoTo,
			pages: Constants.Page,
			initialized: false,
			showSubmitDocumentation: false,
			isVerizonForwardFlow: false,
			enrollment: null,
			showUpdateInfo: true,
			showTabs: showTabs,
			activeTab: null,
			tabs: Constants.Page
		});

		$scope.$on('ebb-approved', function() {
            vm.showReprint = false;
        });

		$rootScope.$on('$locationChangeSuccess', function(){
			var path = $location.path();

			//If its already initialized and gets to myAccount base path, force init
			if(vm.initialized && path == myAccountState.url){
				init();
			}

		})

		function init() {
			tab = $stateParams && $stateParams.tabSelected || Constants.Page.MyAccountDetails;
			
			if ($stateParams && !$stateParams.fromLogin) {
				MyAccountService.GetApplication(getApplicationCallback);
			}
			else {
				getApplicationCallback(true);
			}
			vm.initialized = true;
		}

		function getApplicationCallback(success) {
			
			if (success) {
				$rootScope.$broadcast('enrollment-changed');
				
				showTabs();
				
				if (tab != $state.current.name) {
					MyAccountService.GoTo(tab);
				}			
			}
			else {
				FlowService.goToLogin();
			}
		}

		function logOut() {
			$rootScope.$broadcast('close-air-message');
			MyAccountService.LogOut();
		}
		
		function showTabs() {
			
			var application = DTOFactory.Application();
			var stepCode = Utils.getProofStepCode();

			vm.showSubmitDocumentation = application.hasPendingProof && application.Status.Code == Constants.Status.PENDING;
			vm.isVerizonForwardFlow = application.BenefitTypeCode == Constants.BenefitType.VZF;

			if(stepCode != null && stepCode == Constants.UploadProofStep.RequiredDocumentationSubmitSuccess)
			{
				vm.showSubmitDocumentation = false && application.Status.Code == Constants.Status.PENDING;
			}

			var showUpdate = application.Status.Code == Constants.Status.ENROLLED;
			var showCancel = application.Status.Code == Constants.Status.ENROLLED;

			vm.showUpdateInfo = showUpdate;
			vm.fullName = application.Applicant.FirstName 
				+ ' '
				+ (application.Applicant.MiddleName ? application.Applicant.MiddleName + ' ' : '')
				+ application.Applicant.LastName;
			
			MyAccountService.SetActionsVisibility(showUpdate, showCancel);
		}
	}
})();
