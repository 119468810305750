(function () {
	'use strict';

	angular.module('l4b')
		.component('enrollmentDetailsTable', {
			templateUrl: 'html/shared/enrollmentDetailsTable/enrollmentDetailsTable.html',
			controller: EnrollmentDetailsController,
			controllerAs: 'vm',
			bindings: {
			}
		});

	function EnrollmentDetailsController(Constants, StorageService, RestService, Utils, $scope) {
		var vm = this;
		
		angular.extend(vm, {
			$onInit: init,
			enrollment: null,
			requirements: [],
			showUpdateInfo: false
		});

		function init(){
			onEnrollmentChange($scope.$parent.vm.enrollment);

			$scope.$watch(function(scope) { return scope.$parent.vm.enrollment; },
				function(newValue, oldValue){
					if (newValue===oldValue) return;

					onEnrollmentChange(newValue);
			});
		}

		function onEnrollmentChange(newValue) {

			if (!newValue) {
				return;
			}
			
			vm.enrollment = newValue;
			
			vm.fullName = vm.enrollment.Applicant.FirstName + ' ' + vm.enrollment.Applicant.LastName;
			
			vm.address = addressMultiline(vm.enrollment.Address);
			vm.shippingAddress = addressMultiline(vm.enrollment.ShippingAddress);

			vm.showUpdateInfo = vm.enrollment.canEditInfo;
		}

		function addressMultiline(address) {
			if (address) {
				var str = '';
				str += address.Line1 ? address.Line1 : '';
				str += address.Line2 ? '<br>' + address.Line2 : '';
				str += '<br>' + address.City;
				str += ' ' + address.State;
				str += ' ' + address.ZipCode;
				return str;
			}
			return address;
		}
	}
})();
